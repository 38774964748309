
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, ChevronRight, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemNoDate,
    CreateFeedItemBillWithDescription,
    CreateFeedItemAPI,
    CreateFeedItemAPITest,
    CreateFeedAPI,
    CreateFeedAPIX,
    CreateFeedAPIDepartmentUS,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import ArmyDocuments from './ArmyDocuments';
import NavyDocuments from './NavyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import CIADocuments from './CIADocuments';
import TSADocuments from './TSADocuments';
import SecretService from './SecretService';
import FEMADocuments from './FEMADocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import DoSPress from './DoSPress';
import ArmsControl from './ArmsControl';
import DepartmentOfAgricultureDocuments from './DepartmentOfAgricultureDocuments';
import DepartmentOfEducationDocs from './DepartmentOfEducationDocs';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfVeteransAffairsDocs from './DepartmentOfVeteransAffairsDocs';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';

import GenerateFeed from './GenerateFeed';
import PresidentDisplay from './PresidentDisplay';
import PresidentDisplayOnPage from './PresidentDisplayOnPage';
import VicePresidentDisplayOnPage from './VicePresidentDisplayOnPage';
import PresidentDisplayOnCarouselDesk from './PresidentDisplayOnCarouselDesk';
import VicePresidentDisplayOnCarouselDesk from './VicePresidentDisplayOnCarouselDesk';
import GenerateFeedDepartment from './GenerateFeedDepartment';

var collectionCounter = 0
var carouselInterval = 30000;
var carouselInterval = 60000;
const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const ExecutiveBranch = () =>
 {
   render()

   {
    const [show, setShow] = useState(true);

    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  

    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
  
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    const [whiteHouseBreifingModalIsOpen, setWhiteHouseBreifingModalIsOpen] = useState(false)
    const [presidentialDocumentsModalIsOpen, setPresidentialDocumentsModalIsOpen] = useState(false)
    const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)

    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)

    
    // const [ModalIsOpen, setModalIsOpen] = useState(false)
    const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
    const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
    const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
    const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
    const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
    const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
    const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
    const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
    const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
    const [agDocsModalIsOpen, setAgDocsModalIsOpen] = useState(false)
    const [eduDocumentsModalIsOpen, setEduDocumentsModalIsOpen] = useState(false)
    const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
    const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
    const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
    const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
    const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
    const [federalRegisterLaborDepartmentModalIsOpen , setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
    const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen , setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen ] = useState(false)
    const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen , setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
    const [federalRegisterVeteransAffairsDepartmentModalIsOpen , setFederalRegisterVeteransAffairsDepartmentModalIsOpen ] = useState(false)
    const [federalRegisterHomelandSecurityDepartmentModalIsOpen , setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
    const [federalRegisterEducationDepartmentModalIsOpen , setFederalRegisterEducationDepartmentModalIsOpen ] = useState(false)

    const [
      number1ModalIsOpen, setNumber1ModalIsOpen,
      number2ModalIsOpen, setNumber2ModalIsOpen,
      number3ModalIsOpen, setNumber3ModalIsOpen,
      number4ModalIsOpen, setNumber4ModalIsOpen,
      number5ModalIsOpen, setNumber5ModalIsOpen,
      number6ModalIsOpen, setNumber6ModalIsOpen,
      number7ModalIsOpen, setNumber7ModalIsOpen,
      number8ModalIsOpen, setNumber8ModalIsOpen,
      number9ModalIsOpen, setNumber9ModalIsOpen,
      number10ModalIsOpen, setNumber10ModalIsOpen,
      number11ModalIsOpen, setNumber11ModalIsOpen,
      number12ModalIsOpen, setNumber12ModalIsOpen,
      number13ModalIsOpen, setNumber13ModalIsOpen,
      number14ModalIsOpen, setNumber14ModalIsOpen,
      number15ModalIsOpen, setNumber15ModalIsOpen,
      number16ModalIsOpen, setNumber16ModalIsOpen,
      number17ModalIsOpen, setNumber17ModalIsOpen,
      number18ModalIsOpen, setNumber18ModalIsOpen,
      number19ModalIsOpen, setNumber19ModalIsOpen,
      number20ModalIsOpen, setNumber20ModalIsOpen,
      number21ModalIsOpen, setNumber21ModalIsOpen,
      number22ModalIsOpen, setNumber22ModalIsOpen,
      number23ModalIsOpen, setNumber23ModalIsOpen,
      number24ModalIsOpen, setNumber24ModalIsOpen,
      number25ModalIsOpen, setNumber25ModalIsOpen,
    ] = useState(false);
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
              {/* <div id='sectionHeading'>
            
            <h3 id='branchHeading'><People></People> Executive

              <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.whitehouse.gov/', "_blank")
              }} >whitehouse.gov</Button>



            <Form id='whiteHouseSearchForm'>
            
                <Form.Group className="mb-3 d-flex" >

                  <Form.Control id="congressSearch" placeholder="Search whitehouse.gov"
                    type="text" />
 <Button variant="primary" id='navbarAboutButtonDesktop' onClick={() => whiteHouseSearch()}>
                  Search
                </Button>
                </Form.Group>

               
                


          
             
            </Form>
            </h3>
            
            </div> */}
<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/executiveBranch.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> <h3  >Executive Branch </h3></span>
<br></br>
<div style={{ color: 'white', width: '80%'}}>
<span> 
The power of the Executive Branch is vested in the President of the United States, who also acts as head of state and Commander-in-Chief of the armed forces. The President is responsible for implementing and enforcing the laws written by Congress and, to that end, appoints the heads of ...</span>

<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The power of the Executive Branch is vested in the President of the United States, who also acts as head of state and Commander-in-Chief of the armed forces. The President is responsible for implementing and enforcing the laws written by Congress and, to that end, appoints the heads of the federal agencies, including the Cabinet. The Vice President is also part of the Executive Branch, ready to assume the Presidency should the need arise.</p>

<p>The Cabinet and independent federal agencies are responsible for the day-to-day enforcement and administration of federal laws. These departments and agencies have missions and responsibilities as widely divergent as those of the Department of Defense and the Environmental Protection Agency, the Social Security Administration and the Securities and Exchange Commission.</p>

<p>Including members of the armed forces, the Executive Branch employs more than 4 million Americans.</p>

<p><b>The President</b></p>


<p>The President is both the head of state and head of government of the United States of America, and Commander-in-Chief of the armed forces.</p>

<p>Under Article II of the Constitution, the President is responsible for the execution and enforcement of the laws created by Congress. Fifteen executive departments — each led by an appointed member of the President’s Cabinet — carry out the day-to-day administration of the federal government. They are joined in this by other executive agencies such as the CIA and Environmental Protection Agency, the heads of which are not part of the Cabinet, but who are under the full authority of the President. The President also appoints the heads of more than 50 independent federal commissions, such as the Federal Reserve Board or the Securities and Exchange Commission, as well as federal judges, ambassadors, and other federal offices. The Executive Office of the President (EOP) consists of the immediate staff to the President, along with entities such as the Office of Management and Budget and the Office of the United States Trade Representative.</p>

<p>The President has the power either to sign legislation into law or to veto bills enacted by Congress, although Congress may override a veto with a two-thirds vote of both houses. The Executive Branch conducts diplomacy with other nations and the President has the power to negotiate and sign treaties, which the Senate ratifies. The President can issue executive orders, which direct executive officers or clarify and further existing laws. The President also has the power to extend pardons and clemencies for federal crimes.</p>

<p>With these powers come several responsibilities, among them a constitutional requirement to “from time to time give to the Congress Information of the State of the Union, and recommend to their Consideration such Measures as he shall judge necessary and expedient.” Although the President may fulfill this requirement in any way he or she chooses, Presidents have traditionally given a State of the Union address to a joint session of Congress each January (except in inaugural years) outlining their agenda for the coming year.</p>

<p>The Constitution lists only three qualifications for the Presidency — the President must be at least 35 years of age, be a natural born citizen, and must have lived in the United States for at least 14 years. And though millions of Americans vote in a presidential election every four years, the President is not, in fact, directly elected by the people. Instead, on the first Tuesday after the first Monday in November of every fourth year, the people elect the members of the Electoral College. Apportioned by population to the 50 states — one for each member of their congressional delegation (with the District of Columbia receiving 3 votes) — these Electors then cast the votes for President. There are currently 538 electors in the Electoral College.</p>

<p>President Joseph R. Biden is the 46th President of the United States. He is, however, only the 45th person ever to serve as President; President Grover Cleveland served two nonconsecutive terms, and thus is recognized as both the 22nd and the 24th President. Today, the President is limited to two four-year terms, but until the 22nd Amendment to the Constitution, ratified in 1951, a President could serve an unlimited number of terms. Franklin Delano Roosevelt was elected President four times, serving from 1932 until his death in 1945; he is the only President ever to have served more than two terms.</p>

<p>By tradition, the President and the First Family live in the White House in Washington, D.C., also the location of the President’s Oval Office and the offices of his or her senior staff. When the President travels by plane, his or her aircraft is designated Air Force One; the President may also use a Marine Corps helicopter, known as Marine One while the President is on board. For ground travel, the President uses an armored presidential limousine.</p>

<p><b>The Vice President</b></p>

<p>The primary responsibility of the Vice President of the United States is to be ready at a moment’s notice to assume the Presidency if the President is unable to perform his or her duties. This can be because of the President’s death, resignation, or temporary incapacitation, or if the Vice President and a majority of the Cabinet judge that the President is no longer able to discharge the duties of the presidency.</p>

<p>The Vice President is elected along with the President by the Electoral College. Each elector casts one vote for President and another for Vice President. Before the ratification of the 12th Amendment in 1804, electors only voted for President, and the person who received the second greatest number of votes became Vice President.</p>

<p>The Vice President also serves as the President of the United States Senate, where he or she casts the deciding vote in the case of a tie. Except in the case of tie-breaking votes, the Vice President rarely actually presides over the Senate. Instead, the Senate selects one of their own members, usually junior members of the majority party, to preside over the Senate each day.</p>

<p>Kamala D. Harris is the 49th Vice President of the United States. She is the first woman and first woman of color to be elected to this position. The duties of the Vice President, outside of those enumerated in the Constitution, are at the discretion of the current President. Each Vice President approaches the role differently — some take on a specific policy portfolio, others serve simply as a top adviser to the President. Of the 48 previous Vice Presidents, nine have succeeded to the Presidency, and five have been elected to the Presidency in their own right.</p>

<p>The Vice President has an office in the West Wing of the White House, as well as in the nearby Eisenhower Executive Office Building. Like the President, he or she also maintains an official residence, at the United States Naval Observatory in Northwest Washington, D.C. This peaceful mansion has been the official home of the Vice President since 1974 — previously, Vice Presidents had lived in their own private residences. The Vice President also has his or her own limousine, operated by the United States Secret Service, and flies on the same aircraft the President uses — but when the Vice President is aboard, the craft are referred to as Air Force Two and Marine Two.</p>



<p><b>The Executive Office of the President</b></p>

<p>Every day, the President of the United States is faced with scores of decisions, each with important consequences for America’s future. To provide the President with the support that he or she needs to govern effectively, the Executive Office of the President (EOP) was created in 1939 by President Franklin D. Roosevelt. The EOP has responsibility for tasks ranging from communicating the President’s message to the American people to promoting our trade interests abroad.</p>

<p>The EOP, overseen by the White House Chief of Staff, has traditionally been home to many of the President’s closest advisers. While Senate confirmation is required for some advisers, such as the Director of the Office of Management and Budget, most are appointed with full Presidential discretion. The individual offices that these advisors oversee have grown in size and number since the EOP was created. Some were formed by Congress, others as the President has needed them — they are constantly shifting as each President identifies his or her needs and priorities. Perhaps the most visible parts of the EOP are the White House Communications Office and Press Secretary’s Office. The Press Secretary provides daily briefings for the media on the President’s activities and agenda. Less visible to most Americans is the National Security Council, which advises the President on foreign policy, intelligence, and national security.</p>

<p>There are also a number of offices responsible for the practicalities of maintaining the White House and providing logistical support for the President. These include the White House Military Office, which is responsible for services ranging from Air Force One to the dining facilities, and the Office of Presidential Advance, which prepares sites remote from the White House for the President’s arrival.</p>

<p>Many senior advisors in the EOP work near the President in the West Wing of the White House. However, the majority of the staff is housed in the Eisenhower Executive Office Building, just a few steps away and part of the White House compound.</p>


<p><b>The Cabinet</b></p>

<p>The Cabinet is an advisory body made up of the heads of the 15 executive departments. Appointed by the President and confirmed by the Senate, the members of the Cabinet are often the President’s closest confidants. In addition to running major federal agencies, they play an important role in the Presidential line of succession — after the Vice President, Speaker of the House, and Senate President pro tempore, the line of succession continues with the Cabinet offices in the order in which the departments were created. All the members of the Cabinet take the title Secretary, excepting the head of the Justice Department, who is styled Attorney General.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/
     </Link>
 </div>

       </Modal>

              {/* <div id='branchPage'>

<Container class='bgcolor' >

  <Container id='carouselBufferOne'>

    <Row>

    <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                        <h4 id='departmentOfDefenseHeadingInCol' onClick={(e) => {
              e.preventDefault();
              window.open('/us/whitehouse', "_self")}}><b> White House</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>
            
         
                <Row> 
            
                          <h4 onClick={() => window.open('/us/presidents', "_self")} id='presidentialFeedHeading'><b>President</b></h4>
             
<PresidentDisplayOnPage/>
                
</Row>   
<Row>
<h4 onClick={() => window.open('/us/vicepresidents', "_self")} id='presidentialFeedHeading'><b>Vice President</b></h4>
    
             <VicePresidentDisplayOnPage/>
      
             </Row>          
                </Carousel.Item>

                </Carousel>
                        </div>
                      </Col> 
                          </Row>





  </Container>
</Container>

</div>
                      */}

                {/* <Carousel.Item interval={carouselInterval}>

        <h4 id='presidentialFeedHeading' onClick={() =>
          openModal('Presidential Documents',
            <HeavyDependencyComponent
              url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at'
              name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />)}><b>Presidential Documents</b></h4>
        <div id='whdocs'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at', 'whdocs', 
          'Presidential Documents', 'Executive', 
          'N/A', 'Document', GenerateFeed)}

</Carousel.Item>

<Carousel.Item interval={carouselInterval}>

        <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Executive Orders',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at'
                                name='Executive Orders' branch='Executive' topic='N/A' buttonName='Document' />)}><b> Executive Orders</b></h4>
        <div id='executiveOrders'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at', 'executiveOrders', 
                            'Executive Orders', 'Executive', 
                            'N/A', '', GenerateFeed)}
</Carousel.Item>  

                             <Carousel.Item interval={carouselInterval}>

        <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Executive Orders',
                            <PresidentialProclamations/>)}><b> Presidential Proclamations</b></h4>
        <div id='presidentialProclomations'></div>
        
        {CreateFeedItemExecutiveOrders('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bcorrection%5D=0&conditions%5Bpresident%5D=&conditions%5Bpresidential_document_type%5D=proclamation&conditions%5Bsigning_date%5D%5Byear%5D=&conditions%5Btype%5D%5B%5D=PRESDOCU', 'presidentialProclomations', 'Presidential Proclamations', 'Executive', 'N/A', PresidentialProclamations)}
</Carousel.Item>   
<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/whitehouse' >View all White House news</Button>
</div>
</Col>

</div>



</Carousel.Item> */}
         

         <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />



         <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferOne'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4
              id='departmentOfDefenseHeadingInCol'
              
              onClick={(e) => {
                e.preventDefault();
                window.open('/us/whitehouse', "_self");
              }}
            >
              <b> White House</b>
              <ChevronRight id='clickChevron'></ChevronRight>
            </h4>

            <Carousel id='carouselInColumn' controls={false}>

     
                <Carousel.Item interval={carouselInterval}>
<Row>
  <Col>
  <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('White House Briefing Room',
                              <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at'
                                name='White House Briefing Room' branch='Executive' topic='N/A' buttonName='' />)}><b>Briefing Room</b></h4>
                          <div id='wh'></div>
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at', 'wh', 
                            'White House Briefing Room', 'Executive', 
                            'N/A', '', GenerateFeed)}


</Col>
<Col>
<h4 id='presidentialFeedHeading' onClick={() =>
                    openModal('Executive Orders',
                    <PresidentialProclamations/>)}><b> Presidential Actions</b></h4>
<div id='pa'></div>


                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions', 'pa', 
                            'Presidential Actions', 'Executive', 
                            'N/A', '', GenerateFeed)}
</Col>

                    </Row>
</Carousel.Item>  
<Carousel.Item interval={carouselInterval}>
<Row>

<Col>
<h4 id='presidentialFeedHeading' onClick={() =>
  openModal('Presidential Documents',
    <GenerateFeed
      url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at'
      name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />)}><b>Presidential Documents</b></h4>
<div id='whdocs'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at', 'whdocs', 
  'Presidential Documents', 'Executive', 
  'N/A', 'Document', GenerateFeed)}


</Col>
<Col>

                    </Col>

                    </Row>
</Carousel.Item>  
              {/* <Carousel.Item interval={carouselInterval}>
                <Row>
                  <Col>
                    <h4
                      onClick={() => window.open('/us/presidents', "_self")}
                      id='presidentialFeedHeading'
                    >
                      <b>President</b>
                    </h4>
                    <PresidentDisplayOnCarouselDesk />
                  </Col>
                  <Col>
                    <h4
                      onClick={() => window.open('/us/vicepresidents', "_self")}
                      id='presidentialFeedHeading'
                    >
                      <b>Vice President</b>
                    </h4>
                    <VicePresidentDisplayOnCarouselDesk />
                  </Col>
                </Row>
              </Carousel.Item> */}


                     <Carousel.Item interval={carouselInterval}>
<Row>

<Col>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/whitehouse' >View all White House news</Button>
</div>
</Col>

</div>
</Col>
</Row>


</Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>









       
                      
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                    
                    <Row>
                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofstate', "_self")}><b>Department of State</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>



                <h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfStateModalIsOpen(true)}><b>News</b></h4>
                


               

                  <div id='stateDepartment'></div>
                                    
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
                  'stateDepartment', 'Department of State News', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
                            name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
        </Modal>

</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setStateDocumentsModalIsOpen(true)}><b>Documents</b></h4>


  <div id='frState'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department', 'frState', 'Department of State Documents', 'Executive', 'N/A', FederalRegisterStateDepartment)}



<Modal id='whiteHouseDocsModal' show={stateDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStateDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Documents</h3></Modal.Header>
                  <FederalRegisterStateDepartment/>
                </Modal>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>

   


</Carousel.Item>




<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setArmsControlModalIsOpen(true)}><b>Arms Control and International Security</b></h4>




    <div id='armsControl'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/arms-control-and-international-security.xml', 'armsControl', 'Arms Control and International Security', 'N/A', 'Foreign Affairs', ArmsControl)}




 
  <Modal id='whiteHouseDocsModal' show={armsControlModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmsControlModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Arms Control and International Security</h3></Modal.Header>
    <ArmsControl/>
</Modal>
   


</Carousel.Item>



<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofstate' >View all Department of State news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>
                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentoftreasury', "_self")}><b>Department of Treasury</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
            
      
 

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setLegislativePageBillsEnrolledModalIsOpen(true)}><b>Documents</b></h4>


<div id='treasuryDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=treasury-department', 'treasuryDocs', 'Department of Treasury Documents', 'Executive', 'N/A', DepartmentOfTreasuryDocs)}



<Modal id='whiteHouseDocsModal' show={legislativePageBillsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLegislativePageBillsEnrolledModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury Documents</h3></Modal.Header>
      <DepartmentOfTreasuryDocs/>
    </Modal>
  




</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoftreasury' >View all Department of Treasury news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                 
                      </div>

                  </Col>


                      





                    </Row>
                  </Container>
                </Container>
              </div>
            <div id='branchPage'>
            
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>

                
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofdefense', "_self")}><b>Department of Defense</b><ChevronRight id='clickChevron'></ChevronRight></h4>


                        <Carousel id='carouselInColumn' controls={false}  >


                        <Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>

<div id='defenseDepartment2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment2', 
'Department of Defense', 'Executive', 'Foreign Affairs', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>

</Carousel.Item>


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading' onClick={() => setFederalRegisterDefenseDepartmentModalIsOpen(true)}><b>Documents</b></h4>

<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}


<Modal id='departmentOfStateModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
  <FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>
<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> Army Documents</b></h4>

<div id='armyDocs'></div>

{CreateFeedAPIDepartmentUS('&table_name=defense&agency=Army%20Department', 'armyDocs', 'Army Documents', 'Executive', 'N/A','Document', ArmyDocuments)}


<Modal id='departmentOfStateModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
  <FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >





<div id='legislativeConInCol'>


<Button id='viewFullLegislativePageButton' size='lg' href='/us/departmentofdefense' >View all Department of Defense news</Button>

</div>


</Carousel.Item>


</Carousel>

                      


                      
                    </div>
                  </Col>

                  <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofhomeland', "_self")}><b>Department of Homeland Security</b><ChevronRight id='clickChevron'></ChevronRight></h4>


                        <Carousel id='carouselInColumn' controls={false}  >


                        <Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b>Documents</b></h4>

<div id='homelandDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department', 'homelandDocs', 'Homeland Security Documents', 'Executive', 'N/A', HomelandSecurityDocuments)}





</Carousel.Item>


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> Coast Guard Documents</b></h4>

<div id='coastGuard'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=coast-guard', 'coastGuard', 'Coast Guard', 'Executive', 'N/A', CoastGuardDocuments)}


<Modal id='departmentOfStateModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
  <FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>
<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> FEMA Documents</b></h4>

<div id='fema'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-emergency-management-agency', 'fema', 'FEMA Documents', 'Executive', 'N/A', FEMADocuments)}


<Modal id='departmentOfStateModal' show={federalRegisterDefenseDepartmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFederalRegisterDefenseDepartmentModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
  <FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >





<div id='legislativeConInCol'>


<Button id='viewFullLegislativePageButton' size='lg' href='/us/departmentofhomeland' >View all Department of Homeland Security news</Button>

</div>


</Carousel.Item>


</Carousel>

                      


                      
                    </div>
                  </Col>




                  </Row>
                </Container>
              </Container>
          
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>

                  <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofjustice', "_self")}><b>Department of Justice</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
                      <Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setPresidentialModalIsOpen(true)}><b>News</b></h4>


<div id='justiceNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                      'Department of Justice News', 'Executive', 
                      'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={presidentialModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPresidentialModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
                <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                                name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
              </Modal>




</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setImmigrationModalIsOpen(true)}><b>Documents</b></h4>


<div id='justiceDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'justiceDocs', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}


<Modal id='whiteHouseDocsModal' show={immigrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setImmigrationModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
      <DepartmentOfJusticeDocs/>
    </Modal>




</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofjustice' >View all Department of Justice news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>
                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentoftransportation', "_self")}><b>Department of Transporation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                 
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setNewLawsModalIsOpen(true)}><b>Documents</b></h4>




  <div id='transDepartment'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'transDepartment', 'Department of Transporation Docs', 'Executive', 'N/A', DepartmentOfTransportationDocs)}




<Modal id='whiteHouseDocsModal' show={newLawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewLawsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation</h3></Modal.Header>
  <DepartmentOfTransportationDocs />
</Modal>



</Carousel.Item>

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setImmigrationModalIsOpen(true)}><b>Commercial Space Transporation Office</b></h4>


<div id='spaceDocs'></div>

{CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Commercial%20Space%20Transportation%20Office', 'spaceDocs', 'Commercial Space Transporation Office', 
'Executive', 'N/A', 'Document', CommercialSpaceTransportationOffice)}


<Modal id='whiteHouseDocsModal' show={immigrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setImmigrationModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Commercial Space Transporation Office</h3></Modal.Header>
      <CommercialSpaceTransportationOffice/>
    </Modal>
  



</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoftransportation' >View all Department of Transportation news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

                      </div>
                    </Col>

                  </Row>
                </Container>
              </Container>
         
            </div>

            <div id='branchPage'>

              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                  <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentoftheinterior', "_self")}><b>Department of the Interior</b><ChevronRight id='clickChevron'></ChevronRight></h4>
               
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            


<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setDemocracyHumanRightsLaborModalIsOpen(true)}><b>Documents</b></h4>


<div id='interiorDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=interior-department', 'interiorDocs', 'Department of Interior Documents', 'Executive', 'N/A', DepartmentOfTheInteriorDocs)}


<Modal id='whiteHouseDocsModal' show={democracyHumanRightsLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDemocracyHumanRightsLaborModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior Documents</h3></Modal.Header>
      <DepartmentOfTheInteriorDocs/>
    </Modal>




</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoftheinterior' >View all Department of the Interior news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofagriculture', "_self")}><b>Department of Agriculture</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}><b>News</b></h4>


                

<div id='agricultureDepartment'></div>

{CreateFeedItem('https://www.usda.gov/rss/home.xml', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', DepartmentOfAgriculture)}

                      
<Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
  <DepartmentOfAgriculture />
</Modal>

</Carousel.Item>

<Carousel.Item interval={carouselInterval}>


  <h4 id='departmentOfJusticeHeading' onClick={() => setAgDocsModalIsOpen(true)}><b>Documents</b></h4>
  <div id='agDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=agriculture-department', 'agDocs', 'Department of Agriculture Documents', 'Executive', 'N/A', DepartmentOfAgricultureDocuments)}

 
 

    <Modal id='whiteHouseDocsModal' show={agDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgDocsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture Documents</h3></Modal.Header>
              <DepartmentOfAgricultureDocuments/>
            </Modal>





</Carousel.Item>





<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofagriculture' >View all Department of Agriculture news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                      </div>
                    </Col>

                
                   


                    




                  </Row>
                </Container>
              </Container>
            </div>

            {/* Transportation and Energy (Dead Feeds)


              <Carousel.Item interval={carouselInterval}>
                <div  >
                  <Container class='bgcolor' >
                    <Container id='carouselBufferTwo'>
                      <h3 id='branchHeading'><People></People> <b>Executive</b></h3>
                      <Row>
                        <Col id='legislativeColLeft'>
                          <div id='columnLefttPadding'>


                            <h4 id='departmentOfDefenseHeading'><b>Department of Transportation</b></h4>

                            <div id='healthAndHumanServicesDepartment'></div>
                            <div id='healthAndHumanServicesDepartment2'></div>
                            {createDepartmentOfHealthAndHumanServices()}

                            <Button size='lg' id='departmentOfDefenseViewMoreButton' onClick={() => setDepartmentOfTransportationModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

                            <Modal id='departmentOfStateModal' show={departmentOfTransportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTransportationModalIsOpen(false)}>
                              <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Transportation</h3></Modal.Header>
                              <DepartmentOfTransportation/>
                            </Modal>
                          </div>
                        </Col>


                        <Col id='legislativeColRight'>
                          <div id='columnRightPadding'>

                            <h4 id='departmentOfJusticeHeading'><b>Department of Energy</b></h4>

                            <div id='housingAndUrbanDevelopmentDepartment'></div>
                            <div id='housingAndUrbanDevelopmentDepartment2'></div>
                            {createDepartmentOfHousingAndUrbanDevelopment()}
                            <h6 id='viewMoreTag'>
                              <div class="d-grid gap-2">
                                <Button size='lg' id='departmentOfHousingAndUrbanDevelopmentViewMoreButton' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}> <Files size={20}></Files> View More</Button></div>
                            </h6>

                            <Modal id='departmentOfStateModal' show={departmentOfEnergyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
                              <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Energy</h3></Modal.Header>
                              <DepartmentOfEnergy/>
                            </Modal>


                          </div>
                        </Col>





                      </Row>
                    </Container>
                  </Container>
                </div>






              </Carousel.Item>
              */}

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>

                  <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofcommerce', "_self")}><b>Department of Commerce</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}><b>News</b></h4>




<div id='commerceDepartment'></div>

{CreateFeedItem('https://www.commerce.gov/feeds/news', 'commerceDepartment', 'Department of Commerce', 'Executive', 'Economy', DepartmentOfCommerce)}



   


</Carousel.Item>

<Carousel.Item interval={carouselInterval}>


  <h4 id='departmentOfJusticeHeading' onClick={() => setCommerceDocsModalIsOpen(true)}><b>Documents</b></h4>
  <div id='commerceDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=commerce-department', 'commerceDocs', 'Department of Commerce Documents', 'Executive', 'N/A', DepartmentOfCommerceDocuments)}

 
    

    <Modal id='whiteHouseDocsModal' show={commerceDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceDocsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce Documents</h3></Modal.Header>
              <DepartmentOfCommerceDocuments/>
            </Modal>


    
  
   
   


</Carousel.Item>

<Carousel.Item interval={carouselInterval}>


      <h4 id='departmentOfJusticeHeading' onClick={() => setCensusBureauModalIsOpen(true)}><b>Census Bureau Documents</b></h4>
      <div id='defCb'></div>
                    
      {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Census%20Bureau', 'defCb',
              'Census Bureau Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
     
     

        <Modal id='whiteHouseDocsModal' show={censusBureauModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCensusBureauModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Census Bureau Documents</h3></Modal.Header>
                  <CensusBureau />
                </Modal>


    
  
  
   


</Carousel.Item>

<Carousel.Item interval={carouselInterval}>


      <h4 id='departmentOfJusticeHeading' onClick={() => setEconomicAnalysisModalIsOpen(true)}><b>Economic Analysis Bureau Documents</b></h4>
      <div id='economicAnalysis'></div>
                    
                    {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Economic%20Analysis%20Bureau', 'economicAnalysis', 'Economic Analysis Bureau Documents', 'Executive', 'N/A', 'Document', EconomicAnalysisBureau)}

     
      

        <Modal id='whiteHouseDocsModal' show={economicAnalysisModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEconomicAnalysisModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Analysis Bureau Documents</h3></Modal.Header>
                  <EconomicAnalysisBureau />
                </Modal>


    
  

   


</Carousel.Item>



<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofcommerce' >View all Department of Commerce news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentoflabor', "_self")}><b>Department of Labor</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfLaborModalIsOpen(true)}><b>News</b></h4>




  <div id='laborDepartment'></div>

  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=labor&order_by=created_at', 'laborDepartment', 'Department of Labor', 'Executive', 'Economy', 'Document', DepartmentOfLabor)}






<Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
  <DepartmentOfLabor />
</Modal>



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <DepartmentOfHousingAndUrbanDevelopment />
</Modal>


    






</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setEnforcemnetsModalIsOpen(true)}><b>Documents</b></h4>


<div id='laborDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=labor-department', 'laborDocs', 'Department of Labor Documents', 'Executive', 'N/A', DepartmentOfLaborDocs)}



<Modal id='whiteHouseDocsModal' show={enforcementsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEnforcemnetsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor Documents</h3></Modal.Header>
      <DepartmentOfLaborDocs/>
    </Modal>
  








</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoflabor' >View all Department of Labor news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>




                


                    


                    





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>

                  <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofhealthandhumanservices', "_self")}><b>Department of Health and Human Services</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                      
                      <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}><b>News</b></h4>


     

<div id='healthDepartment'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at', 'healthDepartment', 
                            'Department of Health and Human Services', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at'
                              name='Department of Health and Human Services' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>
             
                


                
        
    
  
  
   


</Carousel.Item>

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setCounterterrorismModalIsOpen(true)}><b>Documents</b></h4>


  <div id='healthDocs'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=health-and-human-services-department', 'healthDocs', 'Department of Health and Human Services Documents', 'Executive', 'N/A', DepartmentOfHealthAndHumanServicesDocs)}



<Modal id='whiteHouseDocsModal' show={counterterrorismModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCounterterrorismModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services Documents</h3></Modal.Header>
                  <DepartmentOfHealthAndHumanServicesDocs/>
                </Modal>
              
        


    
  
   
   


</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofhealthandhumanservices' >View all Department of Health and Human Services news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofhousingandurbandevelopment', "_self")}><b>Department of Housing and Urban Development</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>News</b></h4>




  <div id='housingAndUrbanDevelopmentDepartment'></div>

  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at'
                              name='Department of Housing and Urban Development' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>


    






</Carousel.Item>

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setUSCustomsModalIsOpen(true)}><b>Documents</b></h4>


<div id='housingDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department', 'housingDocs', 'Department of Housing and Urban Development Documents', 'Executive', 'N/A', DepartmentOfHousingAndUrbanDevelopmentDocs)}



<Modal id='whiteHouseDocsModal' show={usCustomsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUSCustomsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
      <DepartmentOfHousingAndUrbanDevelopmentDocs/>
    </Modal>
  








</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentofhousing' >View all Department of Housing and Urban Development news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>


         


                    





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >


                <Row>


                <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofeducation', "_self")}><b>Department of Education</b><ChevronRight id='clickChevron'></ChevronRight></h4>


                      <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        


<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setEduDocumentsModalIsOpen(true)}><b>Documents</b></h4>


  <div id='eduDocs'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=education-department', 'eduDocs', 'Department of Education Documents', 'Executive', 'N/A', DepartmentOfEducationDocs)}



<Modal id='whiteHouseDocsModal' show={eduDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEduDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Education Documents</h3></Modal.Header>
                  <DepartmentOfEducationDocs/>
                </Modal>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>


   


</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofeducation' >View all Department of Education news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofenergy', "_self")}><b>Department of Energy</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                       
                      <Carousel id='carouselInColumn' controls={false}  >
            
                      <Carousel.Item interval={carouselInterval}>


<h4 id='departmentOfJusticeHeading' ><b>News</b></h4>




<div id='energyDepartmentNews'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=energy&order_by=created_at', 'energyDepartmentNews', 'Department of Energy News', 'Executive', 'N/A', 'Document', DepartmentOfEnergyDocs)}

</Carousel.Item>
            
            <Carousel.Item interval={carouselInterval}>


<h4 id='departmentOfJusticeHeading' onClick={() => setDiplomaticSecurityModalIsOpen(true)}><b>Documents</b></h4>




<div id='energyDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-department', 'energyDepartment', 'Department of Energy', 'Executive', 'N/A', DepartmentOfEnergyDocs)}

    


    
    <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Energy Documents</h3></Modal.Header>
      <DepartmentOfEnergyDocs />
    </Modal>







</Carousel.Item>


<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setDeclarationModalIsOpen(true)}><b>Energy Information Administration</b></h4>


  <div id='energyInfo'></div>

  {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Energy%20Information%20Administration', 'energyInfo', 'Energy Information Administration', 'Executive', 'N/A', 'Documents', EnergyInformationAdministration)}

{/* 
              
                <Modal id='whiteHouseDocsModal' show={declarationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeclarationModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Information Administration</h3></Modal.Header>
                  <EnergyInformationAdministration/>
                </Modal> */}







</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' ><b>Federal Energy Regulatory Commission</b></h4>


  <div id='energyReg'></div>

  {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Federal%20Energy%20Regulatory%20Commission', 'energyReg', 'Federal Energy Regulatory Commission', 'Executive', 'N/A', FederalEnergyRegulatoryCommission)}

              
                







</Carousel.Item>


<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofenergy' >View all Department of Energy news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>


           
                  

                 

                  
               







                </Row>
              </Container>

            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >


                <Row>


         

                <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofveteransaffairs', "_self")}><b>Department of Veterans' Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
            
      
                      <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' ><b>News</b></h4>


<div id='vaNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=veterans_affairs_news&order_by=created_at', 'vaNews', 'Department of Veterans Affairs Documents', 'Executive', 'N/A', 'Document', DepartmentOfVeteransAffairsDocs)}


  









</Carousel.Item>       

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setBillsEnrolledModalIsOpen(true)}><b>Documents</b></h4>


<div id='vaDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=veterans-affairs-department', 'vaDocs', 'Department of Veterans Affairs Documents', 'Executive', 'N/A', DepartmentOfVeteransAffairsDocs)}



<Modal id='whiteHouseDocsModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Veterans Affairs Documents</h3></Modal.Header>
      <DepartmentOfVeteransAffairsDocs/>
    </Modal>
  









</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofveteransaffairs' >View all Department of Veterans' Affairs news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>

                    {/* <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/doge', "_self")}><b>Department of Government Efficiency</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>



                <h4 id='departmentOfJusticeHeading' ><b>Coming Soon</b></h4>
                


</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/doge' >View all Department of Government Efficiency news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        </div>
                      </Col> */}




<Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        
                      <h4 id='departmentOfJusticeHeading'><b>National Science Foundation News</b></h4>

<div id='nsf'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 
'nsf', 'National Science Foundation Research News', 'Executive', 'Environment', 'Document', NationalScienceFoundation)}


<Modal id='departmentOfStateModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Science Foundation Research News</h3></Modal.Header>
<NationalScienceFoundation />
</Modal>



                      </div>
                    </Col>
                 

                 

                  
                  







                </Row>
              </Container>

            </div>





            <div id='branchPage'>
              <Container class='bgcolor' >


                <Row>

               


                <Col id='legislativeColLeft'>
                    <div id='columnLefttPadding'>

                    <h4 id='departmentOfDefenseHeading'><b>NASA</b></h4>

<div id='nasa'></div>
  
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at', 'nasa', 'NASA', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                
<Modal id='departmentOfStateModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
<NationalAeronauticsAndSpaceAdministration />
</Modal>
                      
                    </div>
                  </Col>

           
<Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        




                      </div>
                    </Col>

         



     
                </Row>
              </Container>

            </div>



              
            <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferOne'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4
              id='departmentOfDefenseHeadingInCol'
              
              onClick={(e) => {
                e.preventDefault();
                window.open('/us/executiveoffice', "_self");
              }}
            >
              <b> Executive Office of the President</b>
              <ChevronRight id='clickChevron'></ChevronRight>
            </h4>

            <Carousel id='carouselInColumn' controls={false}>


            <Carousel.Item interval={carouselInterval}>



            <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/doge', "_self")}><b>Department of Government Efficiency</b><ChevronRight id='clickChevron'></ChevronRight></h4>

<div id='doge' ></div>

{CreateFeedAPIX('&skip=0&schema=united_states_of_america&table=x_account_1856751787644260354_posts&order_by=created_at', 
'doge', 'DOGE News', 'Executive', 'N/A', '', GenerateFeed)}

</Carousel.Item>

                     <Carousel.Item interval={carouselInterval}>
<Row>

<Col>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/executiveoffice' >View all Executive Office news</Button>
</div>
</Col>

</div>
</Col>
</Row>


</Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>      




  


            <br></br><br></br>
      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <ExecutiveBranchMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default ExecutiveBranch;