
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOfficeTrump, CreateFeedAPI, CreateFeedAPIX } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';

import GenerateFeed from './GenerateFeed';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import ExecutiveOfficeTrumpMobile from './ExecutiveOfficeTrumpMobile';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const ExecutiveOfficeTrump = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
  const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
  const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
  const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
  const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
  const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
  const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
  const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
  const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
  const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
  const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
  const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

  const [
    number1ModalIsOpen, setNumber1ModalIsOpen,
    number2ModalIsOpen, setNumber2ModalIsOpen,
    number3ModalIsOpen, setNumber3ModalIsOpen,
    number4ModalIsOpen, setNumber4ModalIsOpen,
    number5ModalIsOpen, setNumber5ModalIsOpen,
    number6ModalIsOpen, setNumber6ModalIsOpen,
    number7ModalIsOpen, setNumber7ModalIsOpen,
    number8ModalIsOpen, setNumber8ModalIsOpen,
    number9ModalIsOpen, setNumber9ModalIsOpen,
    number10ModalIsOpen, setNumber10ModalIsOpen,
    number11ModalIsOpen, setNumber11ModalIsOpen,
    number12ModalIsOpen, setNumber12ModalIsOpen,
    number13ModalIsOpen, setNumber13ModalIsOpen,
    number14ModalIsOpen, setNumber14ModalIsOpen,
    number15ModalIsOpen, setNumber15ModalIsOpen,
    number16ModalIsOpen, setNumber16ModalIsOpen,
    number17ModalIsOpen, setNumber17ModalIsOpen,
    number18ModalIsOpen, setNumber18ModalIsOpen,
    number19ModalIsOpen, setNumber19ModalIsOpen,
    number20ModalIsOpen, setNumber20ModalIsOpen,
    number21ModalIsOpen, setNumber21ModalIsOpen,
    number22ModalIsOpen, setNumber22ModalIsOpen,
    number23ModalIsOpen, setNumber23ModalIsOpen,
    number24ModalIsOpen, setNumber24ModalIsOpen,
    number25ModalIsOpen, setNumber25ModalIsOpen,
  ] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/executiveBranch.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Executive Office of the President </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> 

Every day, the President of the United States is faced with scores of decisions, each with important consequences for America’s future. To provide the President with the support that he or she needs to govern effectively, the Executive Office of the President (EOP) was created in 1939 ...
</span>

<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/administration/executive-office-of-the-president/", "_blank");
   }}>
     https://www.whitehouse.gov/administration/executive-office-of-the-president/
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Office of the President</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>To provide the President with the support that he or she needs to govern effectively, the Executive Office of the President (EOP) was created in 1939 by President Franklin D. Roosevelt. Overseen by the White House Chief of Staff, the EOP has traditionally been home to many of the President’s closest advisors.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/administration/executive-office-of-the-president/", "_blank");
   }}>
     https://www.whitehouse.gov/administration/executive-office-of-the-president/
     </Link>
 </div>

       </Modal>
                     
           
      

       <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferOne'>
      <Row>
      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/doge', "_self")}><b>Department of Government Efficiency</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <Carousel id='carouselInColumn' controls={false}  >
                        
                  
                        
                        <Carousel.Item interval={carouselInterval}>



                <h4 id='departmentOfJusticeHeading' ><b>News from <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg></b></h4>
                
                <div id='doge' ></div>

{CreateFeedAPIX('&skip=0&schema=united_states_of_america&table=x_account_1856751787644260354_posts&order_by=created_at', 
'doge', 'DOGE News', 'Executive', 'N/A', '', GenerateFeed)}

</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/doge' >View all Department of Government Efficiency news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
              
                </div>
              </Col>
      </Row>
    </Container>
  </Container>
</div>
       

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <ExecutiveOfficeTrumpMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default ExecutiveOfficeTrump;