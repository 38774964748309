
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import AddNoteModal from './AddNoteModal';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import { Speak, CreateFeedItem, CreateFeedAPI, CreateFeedAPICommittee, CreateFeedAPIRecentCacheEnvironment } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import SenateAgricultureNutritionAndForestryCommittee from './SenateAgricultureNutritionAndForestryCommittee';
import SenateEnvironmentAndPublicCommittee from './SenateEnvironmentAndPublicCommittee';
import HouseComitteeOnAgricultureMaterials from './HouseComitteeOnAgricultureMaterials';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import GenerateFeed from './GenerateFeed';
import GenerateFeedCommittee from './GenerateFeedCommittee';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/
function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <Card>{props.value}</Card>;
}
function createFullBillFeed() {

  function NumberList(props) {
    const numbers = props.numbers;
    const listItems = numbers.map((number) =>
      // Correct! Key should be specified inside the array.
      <ListItem key={number.toString()} value={number} />
    );
    return (

      <Container><Card>{listItems}</Card></Container>

    );
  }
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const linkChunk = links.toString().substring(36);
      //console.log(billTitle[99])
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)


      ReactDOM.render(
        <NumberList numbers={billTitle} />,
        document.getElementById('root')
      );

      //render(<BillFeed />);



    }













    //inner html <br>





  })();
}

function testmoney() {
  
}








function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  var noteModal = true
     collectionCounter = localStorage.getItem('collectionNumber')
     //console.log(collectionCounter)
   collectionCounter++;
     //console.log(collectionCounter)
   
   var collectionTitles = []
    var collectionDetailLinks = []
   var collectionDocLinks = []
   var collectionNote = []
   
   //collectionTitles.push(saveCollectionTitle)
   //collectionDetailLinks.push(saveCollectionDetailLink)
   //collectionDocLinks.push(saveCollectionDocLink)
   
   
   
    
     
   
    
     
     var title = 'title'.concat(collectionCounter)
     var detailLink = 'detailLink'.concat(collectionCounter)
     var docLink = 'docLink'.concat(collectionCounter)
     var itemNote = 'itemNote'.concat(collectionCounter)
     var itemDate = 'itemDate'.concat(collectionCounter)
     var feedName = 'feedName'.concat(collectionCounter)
     var branchName = 'branchName'.concat(collectionCounter)
     var topicName = 'topicName'.concat(collectionCounter)
 
   
   
     localStorage.setItem(title,saveCollectionTitle)
     
     localStorage.setItem(detailLink,saveCollectionDetailLink)
    
     localStorage.setItem(docLink,saveCollectionDocLink)
     localStorage.setItem(itemNote,saveCollectionNote)
 
     localStorage.setItem(itemDate,saveCollectionDate)
     localStorage.setItem(feedName,saveCollectionFeedName)
     localStorage.setItem(branchName,saveCollectionBranchName)
     localStorage.setItem(topicName,saveCollectionTopicName)
   
     localStorage.setItem('collectionNumber', collectionCounter)
    
     function saveNote() {
   
       var searchItem = document.getElementById('noteModal').value
       
     
       var indexStore = localStorage.getItem('collectionNumber')
       var itemNote = 'itemNote'.concat(collectionCounter)
       
 
       
       localStorage.setItem(itemNote,searchItem)
 
     window.location.reload()
     
       //Override note value null -> "input"
     
     
       
     
     
     
     
     }
 
   //return collectionCounter;
 
 return(
  render(
     <>
     <AddNoteModal></AddNoteModal>
 
     
     
                             </>
   )
 )
   
     //[x] localStorage.getItem('collectionNumber')
     //[x] localStorage.getItem(saveCollectionNote)
 
   
  
     //<Button onClick={() => { noteModal = false; console.log(localStorage.getItem(saveCollectionNote)) }}>test</Button>
   
     //onClick={() => saveNote()}
   
   }



function createHouseCommitteeOnAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AG00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Housing and Urban Development", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseComitteeOnAgriculture'));





 














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnTransportationAndInfrastructure() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=PW00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Transportation and Infrastructure", "Legislative", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTransportationAndInfrastructure'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




function createDepartmentOfTheInterior() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.doi.gov/feeds/list/22038/rss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of the Interior", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('theInteriorDepartment'));





   














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.usda.gov/rss/home.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Agriculture", "Executive", "Environment" )




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('agricultureDepartment'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createDepartmentOfHousingAndUrbanDevelopment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Housing and Urban Development", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('housingAndUrbanDevelopmentDepartment'));





    














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




function createEnvironmentalProtectionAgency() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "EPA", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epa'));



















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createEnvironmentalProtectionAgencyMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "Environmental Protection Agency", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epaMobile'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createNationalScienceFoundation() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nsf.gov/rss/rss_www_discoveries.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Science Foundation Research News", "Executive", "Environment")



        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nsf'));





      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationWeatherServices(){

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');






    feed.items.forEach(item => {

      



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      console.log('nws link: ' + billLink)
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>

        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open('https://www.wpc.ncep.noaa.gov/discussions/hpcdiscussions.php?disc=pmdspd&version=0&fmt=reg', "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Weather Service Updates", "N/A", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nws'));





      













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}





function createNationalAeronauticsAndSpaceAdministration() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nasa.gov/rss/dyn/breaking_news.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "NASA", "Executive", "Environment")


        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nasa'));





  














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createHouseCommitteeOnNaturalResources() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=II00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "NASA", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnNaturalResources'));



   
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);






export const EnvironmentMobile = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [
    number1ModalIsOpen, setNumber1ModalIsOpen,
    number2ModalIsOpen, setNumber2ModalIsOpen,
    number3ModalIsOpen, setNumber3ModalIsOpen,
    number4ModalIsOpen, setNumber4ModalIsOpen,
    number5ModalIsOpen, setNumber5ModalIsOpen,
    number6ModalIsOpen, setNumber6ModalIsOpen,
    number7ModalIsOpen, setNumber7ModalIsOpen,
    number8ModalIsOpen, setNumber8ModalIsOpen,
    number9ModalIsOpen, setNumber9ModalIsOpen,
    number10ModalIsOpen, setNumber10ModalIsOpen,
    number11ModalIsOpen, setNumber11ModalIsOpen,
    number12ModalIsOpen, setNumber12ModalIsOpen,
    number13ModalIsOpen, setNumber13ModalIsOpen,
    number14ModalIsOpen, setNumber14ModalIsOpen,
    number15ModalIsOpen, setNumber15ModalIsOpen,
    number16ModalIsOpen, setNumber16ModalIsOpen,
    number17ModalIsOpen, setNumber17ModalIsOpen,
    number18ModalIsOpen, setNumber18ModalIsOpen,
    number19ModalIsOpen, setNumber19ModalIsOpen,
    number20ModalIsOpen, setNumber20ModalIsOpen,
    number21ModalIsOpen, setNumber21ModalIsOpen,
    number22ModalIsOpen, setNumber22ModalIsOpen,
    number23ModalIsOpen, setNumber23ModalIsOpen,
    number24ModalIsOpen, setNumber24ModalIsOpen,
    number25ModalIsOpen, setNumber25ModalIsOpen,
  ] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return(
    <>


        

<MobileTopNavbar/>



        <div id='homebg'>
         
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
   
        
            <h5 id='branchHeadingMobile' ><Tree></Tree>Environment</h5>

                     <br></br>
               
                     <h5 id='branchHeadingMobile' onClick={() => window.open('/us/recents/environment', "_self")}> <svg style={{gap: '5px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg> Recents <ChevronRight id='clickChevron'></ChevronRight></h5>  
               
                        <Col>
               
                        <Carousel controls={false} >
                        
           
                        
                        <Carousel.Item interval={mobileCarouselInterval}>


<Col>

    
                


               

                  <div class='courtNewsMobile' id='recent3'></div> 
{CreateFeedAPIRecentCacheEnvironment('https://api.govglance.org/posts/recent/topic?limit=1&skip=0&topic=environment', 'recent3', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
                </Col>


     

    
  



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>



<Col>

       

               

                  <div class='courtNewsMobile' id='recent1'></div> 
{CreateFeedAPIRecentCacheEnvironment('https://api.govglance.org/posts/recent/topic?limit=1&skip=1&topic=environment', 'recent1', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
                </Col>


     


   


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>



<Col>


               

                  <div class='courtNewsMobile' id='recent4'></div> 
{CreateFeedAPIRecentCacheEnvironment('https://api.govglance.org/posts/recent/topic?limit=1&skip=2&topic=environment', 'recent4', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
                </Col>


     

    
  

   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>



<Col>


               

                  <div class='courtNewsMobile' id='recent5'></div> 
{CreateFeedAPIRecentCacheEnvironment('https://api.govglance.org/posts/recent/topic?limit=1&skip=3&topic=environment', 'recent5', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
                </Col>


     

    
  

   


</Carousel.Item>

                        </Carousel>
                        
                        </Col>

          
              <Col>


              <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('National Weather Service Update',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at' 
                            name='National Weather Service Update' branch='Executive' topic='Environment' buttonName='Doc' />)}>National Weather Service Update</h5>


                
              <div id='nws'></div>
                    
              {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at', 'nws', 
                            'National Weather Service Update', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                   
                     
            
                </Col>



     
              <Col>


               <h5 id='presidentialFeedHeadingMobile' onClick={() => window.open('/us/epa', "_self")}>EPA <ChevronRight id='clickChevron'></ChevronRight></h5>


                

                  <div class='courtNewsMobile' id='epa'></div>

                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=epa&order_by=created_at', 'epa', 
                            'EPA', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>EPA</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=epa&order_by=created_at' 
                            name='EPA' branch='N/A' topic='Environment' buttonName='Document' />
                          </Modal>
                </Col>

                <Col>
              
              <h5 id='presidentialFeedHeadingMobile'>Senate Committee on Environment and Public Work Materials</h5>
                      <div class='courtNewsMobile'id='senateEnvironmentAndPublicCommittee'></div>
                
                      {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Environment%20and%20Public%20Works&chamber=SENATE', 
    'senateEnvironmentAndPublicCommittee', 
    'Senate Committee on Environment and Public Works', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
              </Col>


                <Modal id='whiteHouseDocsModal' show={epaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEPAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Environmental Protection Agency (EPA)</h3></Modal.Header>
                  <EnvironmentalProtectionAgency />
                </Modal>



             
      
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>National Science Foundation Research News</h5>


                

                  <div class='courtNewsMobile' id='nsf'></div>

                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf', 'National Science Foundation Research News', 'Executive', 'Environment', 'Document', NationalScienceFoundation)}
                 
                </Col>


              
<Modal id='departmentOfStateModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Science Foundation Research News</h3></Modal.Header>
  <NationalScienceFoundation />
</Modal>



            
           
              <Col>


                <h5 id='presidentialFeedHeadingMobile'>NASA</h5>


                

                  <div class='courtNewsMobile' id='nasa'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at', 'nasa', 'NASA', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                
                </Col>


              
                <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
                  <NationalAeronauticsAndSpaceAdministration />
                </Modal>



       
   


               
                <Modal id='whiteHouseDocsModal' show={departmentOfTheInteriorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTheInteriorModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior</h3></Modal.Header>
                  <DepartmentOfTheInterior />
                </Modal>



            

              <Col>


              <h5 id='presidentialFeedHeadingMobile'>Department of Agriculture</h5>


               

                  <div class='courtNewsMobile' id='agricultureDepartment'></div>

                  {CreateFeedItem('https://www.usda.gov/rss/home.xml', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', DepartmentOfAgriculture)}

                </Col>


               
                <Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
                  <DepartmentOfAgriculture />
                </Modal>

          
             <Col>


             <h5 id='presidentialFeedHeadingMobile'>House Committee on Agriculture</h5>


            

                  <div class='courtNewsMobile' id='houseComitteeOnAgriculture'></div>

                  {CreateFeedItem('https://www.usda.gov/rss/home.xml', 'houseComitteeOnAgriculture', 'House Committee on Agriculture', 'Executive', 'Environment', HouseComitteeOnAgriculture)}

                </Col>

                <Col>
                  <h5 id='presidentialFeedHeadingMobile'>House Committee on Agriculture Materials</h5>
 
                  
                     


 <div class='courtNewsMobile' id='houseComitteeOnAgricultueMaterials'></div>
                          
                          {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Agriculture.xml', 'houseComitteeOnAgricultueMaterials', 'House Committee on Agriculture Materials', 'Legislative', 'N/A', HouseComitteeOnAgricultureMaterials)}

                </Col>

                <Col >
                  

                  <h5 id='presidentialFeedHeadingMobile'>Senate Committee on Agriculture, Nutrition, and Forestry Materials</h5>
                  <div class='courtNewsMobile'id='senateAgricultureNutritionAndForestCommittee'></div>
            
                    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Agriculture,%20Nutrition,%20and%20Forestry.xml', 'senateAgricultureNutritionAndForestCommittee', 'Senate Committee on Agriculture, Nutrition, and Forestry', 'Legislative', 'Environment', SenateAgricultureNutritionAndForestryCommittee)}

          
         
              </Col>


                   
                          <Modal id='fullBillModal' show={houseComitteeOnAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseComitteeOnAgricultureModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Agriculture</h3></Modal.Header>
                            <HouseComitteeOnAgriculture />
                          </Modal>

          
              <Col>


               <h5 id='presidentialFeedHeadingMobile' onClick={() => window.open('/us/departmentofhousingandurbandevelopment', "_self")}>Department of Housing and Urban Development</h5>



                  <div class='courtNewsMobile' id='housingAndUrbanDevelopmentDepartment'></div>

                
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at'
                              name='Department of Housing and Urban Development' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>
                </Col>


                



       
              <Col>


              <h5 id='presidentialFeedHeadingMobile'>House Committee on Natural Resources</h5>


          

                  <div class='courtNewsMobile' id='houseCommitteeOnNaturalResources'></div>

                  {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Executive', 'Environment', HouseCommitteeOnNaturalResources)}

                </Col>

                <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile'>Committee on Natural Resources Materials</h5>
                            <div class='courtNewsMobile'id='houseCommitteeOnNaturalResourcesMaterials'></div>
                      
    
    
    
                            {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Natural%20Resources&chamber=HOUSE', 
    'houseCommitteeOnNaturalResourcesMaterials', 
    'House Committee on Natural Resources Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
                        </Col>


                  
                        <Modal id='fullBillModal' show={houseCommitteeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnNaturalResourcesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Natural Resources</h3></Modal.Header>
                          <HouseCommitteeOnNaturalResources />
                        </Modal>

       
              <Col>
x

              <h5 id='presidentialFeedHeadingMobile'>House Committee on Transportation and Infrastructure</h5>


       

                  <div class='courtNewsMobile' id='houseCommitteeOnTransportationAndInfrastructure'></div>

                  {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 'houseCommitteeOnTransportationAndInfrastructure', 'House Committee on Transportation and Infrastructure', 'Executive', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                </Col>

                {/* <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile'>Committee on Transportation and Infrastructure Materials</h5>
                  <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>
                 

                  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Transportation%20and%20Infrastructure.xml', 'houseCommitteeOnTransportationAndInfrastructureMaterials', 'House Committee on Transportation and Infrastructure Materials', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructureMaterials)}

            </Col>


               
                        
                        <Modal id='fullBillModal' show={houseCommitteeOnTransportationAndInfrastructureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Transportation and Infrastructure</h3></Modal.Header>
                          <HouseCommitteeOnTransportationAndInfrastructure />
                        </Modal> */}

    
           


           


            <Col>

            </Col>

          
            <Navbar id='navbarMobileBottom' fixed="bottom">  

            


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>





       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

            
              
    </>
  )
}
 }



  






export default EnvironmentMobile;