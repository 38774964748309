import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useHistory } from "react-router-dom";
import { Spinner, Card, Button, Container, Modal, Form, Navbar } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search, Clipboard, ClipboardCheck, BoxArrowUp, ChevronRight } from 'react-bootstrap-icons';
import './App.css';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + apiKey,
  },
};

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
};

// Country mapping for name, emoji, and URL
const countryMappings = {
  united_states_of_america: { name: "United States", emoji: "🇺🇸", link: "https://govglance.org/us" },
  canada: { name: "Canada", emoji: "🇨🇦", link: "https://govglance.org/canada" },
  mexico: { name: "Mexico", emoji: "🇲🇽", link: "https://govglance.org/mexico" },
  united_kingdom: { name: "United Kingdom", emoji: "🇬🇧", link: "https://govglance.org/uk" },
};

function GenerateRecents() {
  const [data, setData] = useState([]);
  const [feedLength, setFeedLength] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);

  const apiSources = [
    { schema: 'united_states_of_america', url: `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=united_states_of_america` },
    { schema: 'canada', url: `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=canada` },
    { schema: 'mexico', url: `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=mexico` },
    { schema: 'united_kingdom', url: `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=united_kingdom` }
  ];

  useEffect(() => {
    setLoading(true);
    setError(null);

    // Fetch from all API sources
    Promise.all(apiSources.map(source =>
      fetch(source.url, requestOptions)
        .then(res => res.json())
        .then(items => items.map(item => ({ ...item, schema: source.schema }))) // Assign correct schema
    ))
      .then(responses => {
        const mergedData = responses.flat();
        setData(mergedData);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, [feedLength]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to open the share modal dynamically based on country
  const handleShareModalOpen = (schema, itemTable, id) => {
    const baseUrl = 'https://govglance.org';
    const fullUrl = `${baseUrl}/feed/${schema}/${itemTable}/${id}`; // Now correctly assigning schema
    setShareLink(fullUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false);
  };

  const filteredData = data.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <BrowserView>
        <div id='homebg'>
          <DesktopNavbar />
          <div id='searchFormPage'>
            <Form className="text-center">
              <Form.Group className="d-flex justify-content-center align-items-center">
                <Form.Control
                  id="ggSearchCollection"
                  placeholder="Search..."
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
              </Form.Group>
            </Form>
          </div>

          {loading ? <Spinner animation="grow" variant="light" /> : (
            filteredData.length > 0 ? filteredData.map((item, index) => {
              const countryInfo = countryMappings[item.schema] || {}; // Get country details
              const countryEmoji = countryInfo.emoji || "🌍"; // Default globe if unknown
              const countryName = countryInfo.name || "Unknown Country";
              const countryLink = countryInfo.link || "#"; // Default to #

              return (
                <div id='feedPageFeed' key={index}>
                  <Container id="feedContainerNormalView">
                    {/* Country Name & Link */}
                    <Card.Title
                      id="billBodyTextSource"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(countryLink, "_self");
                      }}
                    >
                      {countryEmoji} {countryName} <ChevronRight id='clickChevron'></ChevronRight>
                    </Card.Title>

                    {/* Collection Name - Existing Clickable Title */}
                    <Card.Title
                      id='billBodyTextSource'
                      onClick={() => {
                        history.push({
                          pathname: `/feed/${item.schema}/${item.source}`,  
                          state: {
                            source: item.source,
                            feedName: item.feed_name,
                            feedBranch: item.branch_name,
                            feedTopic: item.topic_name,
                            feedButtonName: 'View More',
                          },
                        });
                      }}
                    >
                      {item.collection_name}
                    </Card.Title>

                    <Card.Title id='billText' onClick={() => window.open(item.url, "_blank")}>
                      {item.title}
                    </Card.Title>

                    <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>

                    <Button id='billLink' onClick={() => window.open(item.url, "_blank")}>
                      <InfoCircle /> Source
                    </Button>
                    <Button id="shareButton" onClick={() => handleShareModalOpen(item.schema, item.source, item.id)}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
  </svg>
</Button>
                  </Container>
                </div>
              );
            }) : <p>No results found.</p>
          )}

          <div className="d-flex justify-content-center align-items-center">
            <Button id="feedViewMoreButton" onClick={() => setFeedLength(feedLength + 20)}>More</Button>
          </div>
        </div>
      </BrowserView>
    </>
  );
}

export default GenerateRecents;
