import React, { useState, useEffect } from 'react';
import {
  Navbar, Container, Button, Modal, Form, Dropdown, Card, Spinner
} from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search, ChevronRight } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import DesktopNavbarSearch from './DesktopNavbarSearch';
import MobileOffCanvas from './MobileOffCanvas';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import useHandleContainerClickRecentsCanada from './UseHandleContainerClickRecentsCanada';
import useHandleContainerClickRecentsMexico from './UseHandleContainerClickRecentsMexico';

const apiKey = process.env.REACT_APP_API_KEY;

// Utility function to get country details
const getCountryDetails = (countrySchema) => {
  const countryMap = {
    'canada': { name: 'Canada', emoji: '🇨🇦', link: '/canada' },
    'mexico': { name: 'Mexico', emoji: '🇲🇽', link: '/mexico' },
    'united_kingdom': { name: 'United Kingdom', emoji: '🇬🇧', link: '/united_kingdom' },
    'united_states_of_america': { name: 'United States', emoji: '🇺🇸', link: '/us' },
    '': { name: 'All Countries', emoji: '🌐', link: '/' } // Default
  };
  return countryMap[countrySchema] || { name: 'Unknown', emoji: '❓', link: '/' };
};

const GovGlanceSearch = () => {
  const { country: initialCountry } = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const handleContainerClickUS = useHandleContainerClickRecents();
  const handleContainerClickCanada = useHandleContainerClickRecentsCanada();
  const handleContainerClickMexico = useHandleContainerClickRecentsMexico();
  const [country, setCountry] = useState(initialCountry || '');
  const [inputText, setInputText] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedLength, setFeedLength] = useState(10);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const handleContainerClick = useHandleContainerClickRecents();
  const [storedData, setStoredData] = useState({
    title: '',
    detailsLink: '',
    docLink: '',
    date: '',
    note: '',
    feedName: '',
    branchName: '',
    topicName: '',
    description: ''
  });

  useEffect(() => {
    const query = queryParams.get('query') || '';
    setInputText(query);
    if (query) {
      runSearch(10, query);
    }
  }, [location]);

  useEffect(() => {
    if (initialCountry === undefined) {
      setCountry('');
    }
  }, [initialCountry]);

  useEffect(() => {
    if (searchTriggered) {
      runSearch(10, inputText);
    }
  }, [country, searchTriggered]);

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    resetSearchState();
    if (selectedCountry) {
      history.push(`/search/${selectedCountry}?query=${encodeURIComponent(inputText)}&limit=${feedLength}`);
    } else {
      history.push(`/search/?query=${encodeURIComponent(inputText)}&limit=${feedLength}`);
    }
  };

  const resetSearchState = () => {
    setData(null);
    setLoading(false);
    setError(null);
    setFeedLength(10);
  };

  const runSearch = (limit, query) => {
    if (!query || query.trim() === '') {
      setError('');
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    const searchTerm = query || inputText;
    const url = country
      ? `https://api.govglance.org/search/?limit=${limit}&skip=0&schema=${country}&search_term=${encodeURIComponent(searchTerm)}`
      : `https://api.govglance.org/search/?limit=${limit}&skip=0&search_term=${encodeURIComponent(searchTerm)}`;

    fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + apiKey } })
      .then((response) => {
        if (response.status === 404) throw new Error('No results found');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setData(data);
        setSearchTriggered(false);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    if (!inputText.trim()) {
      setError('');
      return;
    }

    setSearchTriggered(true);
    setFeedLength(10);
    const newUrl = generateSearchUrl();
    window.history.pushState({ path: newUrl }, '', newUrl);
    runSearch(10, inputText);
  };

  const generateSearchUrl = () => {
    return country
      ? `/search/${country}?query=${encodeURIComponent(inputText)}&limit=${feedLength}`
      : `/search/?query=${encodeURIComponent(inputText)}&limit=${feedLength}`;
  };

  const saveNote = () => {
    const searchItem = document.getElementById('noteModalFeedItem').value;
    const collectionCounter = parseInt(localStorage.getItem('collectionNumber') || '0', 10) + 1;

    localStorage.setItem('collectionNumber', collectionCounter);
    localStorage.setItem(`title${collectionCounter}`, storedData.title);
    localStorage.setItem(`detailLink${collectionCounter}`, storedData.detailsLink);
    localStorage.setItem(`docLink${collectionCounter}`, storedData.docLink);
    localStorage.setItem(`itemNote${collectionCounter}`, searchItem);
    localStorage.setItem(`itemDate${collectionCounter}`, storedData.date);
    localStorage.setItem(`feedName${collectionCounter}`, storedData.feedName);
    localStorage.setItem(`branchName${collectionCounter}`, storedData.branchName);
    localStorage.setItem(`topicName${collectionCounter}`, storedData.topicName);
    localStorage.setItem(`itemDescription${collectionCounter}`, storedData.description);

    window.location.reload();
  };

  const truncateDescription = (description, maxLength) => {
    const strippedText = stripHtmlTags(description);
    return strippedText.length > maxLength ? `${strippedText.slice(0, maxLength)}...` : strippedText;
  };

  const truncateTitle = (title) => {
    return title.length > 180 ? `${title.substring(0, 177)}...` : title;
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleLoadMore = () => {
    setFeedLength(feedLength + 20);
    runSearch(feedLength + 20, inputText);
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  };

  const SaveFeedItem = (title, detailsLink, docLink, date, feedName, branchName, topicName, description) => {
    setStoredData({
      title, detailsLink, docLink, date, feedName, branchName, topicName, description
    });
  };

  

  const renderCard = (item) => {
    const countryDetails = getCountryDetails(country);
    const nameForAPI = item.collection_name.toLowerCase().replace(/\s+/g, '_');
    return (
      <div key={item.id} id='searchPadding' className='d-flex align-items-center justify-content-center'>
        <Container id='searchFeedContainer'>
          <Card.Title
            id="billBodyTextSource"
            onClick={() => handleContainerClick(nameForAPI, item.collection_name )}
          >
            {item.collection_name}
          </Card.Title>
          <Card.Body id='billBodyText' onClick={() => window.open(item.url, "_blank")}>
           {truncateTitle(item.status_title || item.title)}
          </Card.Body>
          <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
          {item.description && (
            <Card.Body id='billBodyText' onClick={() => window.open(item.url, "_blank")}>
              {stripHtmlTags(truncateDescription(item.description, 297))}
            </Card.Body>
          )}
          <Card.Body id='billBodyText'>{item.source.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()).replace(/ Archived/g, '').trim()}</Card.Body>
          <Button id='billLink' onClick={() => window.open(item.url, "_blank")}><InfoCircle /> Source</Button>
          {item.document_url && (
            <Button id='viewBillButton' onClick={() => window.open(item.document_url, "_blank")}><FileText /> Document</Button>
          )}
          <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); SaveFeedItem(item.title, item.url, "", "", "", "", "", stripHtmlTags(truncateDescription(item.description, 297))) }}>
            <FolderPlus />
          </Button>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton ><h3>{storedData.title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {storedData.description}
              {storedData.date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(); setCollectionModalIsOpen(false) }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    );
  };

  return (
    <>
      <MobileView>
        <div id='homebg'>
          <Form className="text-center" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
            <Form.Group className="d-flex justify-content-center align-items-center">
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id='searchCountry'>
                  {getCountryDetails(country).emoji}
                </Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown" drop="down-centered">
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('')}>
                    🌐 All Countries
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('canada')}>
                    🇨🇦 Canada
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('mexico')}>
                    🇲🇽 Mexico
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('united_kingdom')}>
                    🇬🇧 United Kingdom
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('united_states_of_america')}>
                    🇺🇸 United States
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                id='ggSearch'
                size="sm"
                placeholder="Search Gov Glance"
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                autoComplete='off'
              />
              <Button variant="primary" id='ggButton' type="submit">
                <Search />
              </Button>
            </Form.Group>
          </Form>
          {loading && <Spinner animation="grow" variant="light" />}
          {error && <div id='billText' className="error-message">{error === 'No results found' ? `No results found for "${inputText}"` : error}</div>}
          {data && (
            <div>
              {data.map(item => renderCard(item))}
              <div style={{ textAlign: 'center' }}>
                <Button id='searchViewMoreButtonMobile' onClick={handleLoadMore}>More</Button>
              </div>
            </div>
          )}
          <Navbar id='navbarMobileBottom' fixed="bottom">
            <div id='changeBranchDropdownMobile'>
              <MobileOffCanvas />
            </div>
          </Navbar>
        </div>
      </MobileView>
      <BrowserView>
        <div id='homebg'>
          <DesktopNavbarSearch />
          <Container className="text-center">
            <Form className="d-flex justify-content-center align-items-center" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id='searchCountry'>
                  {getCountryDetails(country).emoji}
                </Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown" drop="down-centered">
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('')}>
                    🌐 All Countries
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('canada')}>
                    🇨🇦 Canada
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('mexico')}>
                    🇲🇽 Mexico
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('united_kingdom')}>
                    🇬🇧 United Kingdom
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleCountryChange('united_states_of_america')}>
                    🇺🇸 United States
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                id='ggSearch'
                size="sm"
                placeholder="Search Gov Glance"
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                autoComplete='off'
              />
              <Button variant="primary" id='ggButton' type="submit">
                <Search />
              </Button>
            </Form>
          </Container>
          {loading && <Spinner animation="grow" variant="light" />}
          {error && <div id='billText' className="error-message">{error === 'No results found' ? `No results found for "${inputText}"` : error}</div>}
          {data && (
            <Container>
              {data.map(item => renderCard(item))}
              <div style={{ textAlign: 'center' }}>
                <Button id='searchViewMoreButton' onClick={handleLoadMore}>More</Button>
              </div>
            </Container>
          )}
        </div>
      </BrowserView>
    </>
  );
};

export default GovGlanceSearch;
