import React, { useState, useEffect, memo } from 'react';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import { FileText, FolderPlus, InfoCircle } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";

let collectionCounter = localStorage.getItem('collectionNumber') || 0;

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

const MemberVotes = ({ bioGuideID }) => {
  const history = useHistory();
  const [votesData, setVotesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [selectedVote, setSelectedVote] = useState(null);
  const apiKey = process.env.REACT_APP_API_KEY;

  const fetchVotesData = () => {
    setLoading(true);
    fetch(`https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=all_member_votes&order_by=created_at&filter_column=bio_guide_id&filter_string=${bioGuideID}`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + apiKey,
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch votes data');
      }
      return response.json();
    })
    .then(data => {
      setVotesData(data);
      setLoading(false);
      setError(null);
    })
    .catch(error => {
      console.error('Error fetching votes data:', error);
      setError('Error fetching votes data');
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchVotesData();
  }, [bioGuideID]);

  const saveNote = () => {
    collectionCounter++;
    localStorage.setItem('collectionNumber', collectionCounter);

    const prefix = `collectionItem${collectionCounter}`;
    localStorage.setItem(`${prefix}_title`, selectedVote.title);
    localStorage.setItem(`${prefix}_detailsLink`, selectedVote.url);
    localStorage.setItem(`${prefix}_docLink`, selectedVote.document_link);
    localStorage.setItem(`${prefix}_date`, formatDate(selectedVote.created_at));
    localStorage.setItem(`${prefix}_note`, noteText);
    localStorage.setItem(`${prefix}_feedName`, "All Member Votes");
    localStorage.setItem(`${prefix}_branchName`, "Legislation");
    localStorage.setItem(`${prefix}_topicName`, selectedVote.topic || 'N/A');
    localStorage.setItem(`${prefix}_description`, selectedVote.description || '');
    
    setCollectionModalIsOpen(false);
  };

  const handleOpenModal = (vote) => {
    setSelectedVote(vote);
    setCollectionModalIsOpen(true);
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error fetching data: {error}</p>;
    }
  
    if (!votesData || votesData.length === 0) {
      return <p>No data available.</p>;
    }

    const filteredVotesData = votesData.filter(vote => 
      vote.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredVotesData.map((vote, index) => (
      <div id='fullVotesFeed' key={index}>
        <Card.Title>{vote.legislation_id} - {vote.title}</Card.Title>
        <Card.Body>
          <p>Date: {formatDate(vote.created_at)}</p>
          <p>Status: {vote.status}</p>
          <p>Vote: {vote.vote}</p>
          <Button id='detailsLink' onClick={() => history.push(`/feed/united_states_of_america/all_member_votes/${vote.id}`)}>
            <InfoCircle /> Source
          </Button>
          <Button id='documentLink' onClick={() => window.open(vote.document_link, "_blank")}>
            <FileText /> Document
          </Button>
          <Button id='addToCollectionButton' onClick={() => handleOpenModal(vote)}>
            <FolderPlus /> Add to Collection
          </Button>
        </Card.Body>
      </div>
    ));
  };

  return (
    <>
      <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search by title..."
              type="text"
              color="white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Form.Group>
        </Form>
      </div>
      <div id='fullVotesFeed'>
        {renderData()}
      </div>

      <Modal
        id='fullVotesModal'
        show={collectionModalIsOpen}
        onHide={() => setCollectionModalIsOpen(false)}
        backdrop="static"
        keyboard={true}
        size="xl"
        centered
      >
        <Modal.Header closeButton><h3>{selectedVote?.title}</h3></Modal.Header>
        <Modal.Body>
          <p>{selectedVote?.description || 'No description available'}</p>
          <p>Date: {formatDate(selectedVote?.created_at)}</p>
          <Form>
            <Form.Group>
              <Form.Control
                id="noteModalFeedItem"
                placeholder="Add a note here"
                type="text"
                as="textarea"
                rows={2}
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveNote}>
            Save To Collection
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(MemberVotes);
