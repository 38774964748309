import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPI,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DiplomaticSecurity from './DiplomaticSecurity';
import DemocracyHumanRightsLabor from './DemocracyHumanRightsLabor';
import CounterTerrorism from './CounterTerrorism';
import ArmsControl from './ArmsControl';
import DoSPress from './DoSPress';
import GenerateFeed from './GenerateFeed';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const DepartmentOfStateMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)

        const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
        const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
        const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
        const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
        const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
        const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
        const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
        const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
        const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
        const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
        const [intelligenceMatsModalIsOpen, setIntelligenceMatsModalIsOpen] = useState(false)
        const [armedServicesMatsModalIsOpen, setArmedServicesMatsModalIsOpen] = useState(false)
        const [senateHomelandModalIsOpen, setSenateHomelandModalIsOpen] = useState(false)
        const [senateForeignModalIsOpen, setSenateForeignModalIsOpen] = useState(false)
        const [senateIntelligenceModalIsOpen, setSenateIntelligenceModalIsOpen] = useState(false)
        const [senateArmedServicesModalIsOpen, setSenateArmedServicesModalIsOpen] = useState(false)
        const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
        const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
        const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
        const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
        const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
    var branch = "all";
    const [showBioModal, setBioModal] = useState(false);
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfState.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of State</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.state.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-1/pdf/STATUTE-1-Pg28.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The Department of State plays the lead role in developing and implementing the President’s foreign policy. Major responsibilities include United States representation abroad, foreign assistance, foreign military training programs, countering international crime, and a wide assortment of services to U.S. citizens and foreign nationals seeking entrance to the United States.
          </div>

          {/* <div style={{height: '20px',}}></div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          <b>Establishing Act</b>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
         
          An Act for establishing an Executive Department, to be denominated the Department of Foreign Affairs.
          </div>
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button> */}
        </div>
        <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of State</h3></Modal.Header>
       
       <div id='fullBillFeed' >
       <p>The Department of State plays the lead role in developing and implementing the President’s foreign policy. Major responsibilities include United States representation abroad, foreign assistance, foreign military training programs, countering international crime, and a wide assortment of services to U.S. citizens and foreign nationals seeking entrance to the United States.</p>

<p>The U.S. maintains diplomatic relations with approximately 180 countries — each posted by civilian U.S. Foreign Service employees — as well as with international organizations. At home, more than 5,000 civil employees carry out the mission of the Department.</p>

<p>The Secretary of State serves as the President’s top foreign policy adviser, and oversees 30,000 employees and a budget of approximately $35 billion.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2412%20billion.-,DEPARTMENT%20OF%20STATE,-The%20Department%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2412%20billion.-,DEPARTMENT%20OF%20STATE,-The%20Department%20of
   </Link>
</div>

     </Modal>

        {/* <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Establishing Act</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Section 1. Be it enacted by the Senate and House of Representatives of the United States ofAmerica in Congress assembled, That there
shall be an Executive department, to be denominated the Department ofAn Act for establishing an Executive Department, to be denominated the Department of Foreign Affairs.</p>

<p>Sec. 2. And be it further enacted, That there shall be in the said
department, an inferior officer, to be appointed by the said principal
officer, and to be employed therein as he shall deem proper, and to be
called the chief Clerk in the Department of Foreign Affairs, and who,
whenever the said principal officer shall be removed from office by the
President of the United States, or in any other case of vacancy, shall
during such vacancy have the charge and custody of all records, books
and papers appertaining to the said department.</p>

<p>Sec. 3. And be it further enacted, That the said principal officer,
and every other person to be appointed or employed in the said department, shall, before he enters on the execution of his office or employment, take an oath or affirmation, well and faithfully to execute the trust
committed to him.</p>


<p>Sec. 4. And be it further enacted, That the Secretary for the Department of Foreign Affairs, to be appointed in consequence of this
act, shall forthwith after his appointment, be entitled to have the custody and charge of all records, books and papers in the office of Secretary for the Department of Foreign Affairs, heretofore established by
the United States in Congress assembled.
Approved , July 27, 1789.</p>


<Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-1/pdf/STATUTE-1-Pg28.pdf"); }}>
          <FileText></FileText> View Document
        </Button>

 </div>

       </Modal> */}

            <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfStateModalIsOpen(true)}>News</h5>





  <div class='courtNewsMobile' id='stateDepartment'></div>

  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
                  'stateDepartment', 'Department of State News', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
                            name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
        </Modal>
</Col>






<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setStateDocumentsModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='frState'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department', 'frState', 'Department of State Documents', 'Executive', 'N/A', FederalRegisterStateDepartment)}

</Col>

<Modal id='whiteHouseDocsModal' show={stateDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStateDocumentsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State Documents</h3></Modal.Header>
  <FederalRegisterStateDepartment/>
</Modal>








<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmsControlModalIsOpen(true)}>Arms Control and International Security</h5>




    <div class='courtNewsMobile' id='armsControl'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/arms-control-and-international-security.xml', 'armsControl', 'Arms Control and International Security', 'N/A', 'Foreign Affairs', ArmsControl)}

  </Col>


 
  <Modal id='whiteHouseDocsModal' show={armsControlModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmsControlModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Arms Control and International Security</h3></Modal.Header>
    <ArmsControl/>
  </Modal>



<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setCounterterrorismModalIsOpen(true)}>Counterterrorism</h5>




    <div class='courtNewsMobile' id='counterterrorism'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/counterTerrorism.xml', 'counterterrorism', 'Counterterrorism', 'N/A', 'Foreign Affairs', CounterTerrorism)}

  </Col>



  <Modal id='whiteHouseDocsModal' show={counterterrorismModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCounterterrorismModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Counterterrorism</h3></Modal.Header>
    <CounterTerrorism/>
  </Modal>



<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDemocracyHumanRightsLaborModalIsOpen(true)}>Democracy, Human Rights, and Labor</h5>




    <div class='courtNewsMobile' id='democracyHumanRightsLabor'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/democracy-human-rights-and-labor.xml', 'democracyHumanRightsLabor', 'Democracy, Human Rights, and Labor', 'N/A', 'Foreign Affairs', DemocracyHumanRightsLabor)}

  </Col>



  <Modal id='whiteHouseDocsModal' show={democracyHumanRightsLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDemocracyHumanRightsLaborModalIsOpen(false)}>
    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Democracy, Human Rights, and Labor</h3></Modal.Header>
    <DemocracyHumanRightsLabor/>
  </Modal>










<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDiplomaticSecurityModalIsOpen(true)}>Diplomatic Security</h5>




    <div class='courtNewsMobile' id='diplomaticSecurity'></div>
          

    {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/foreignAffairs-xml/diplomatic-security.xml', 'diplomaticSecurity', 'Diplomatic Security', 'N/A', 'Foreign Affairs', DiplomaticSecurity)}

  </Col>

  <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Diplomatic Security</h3></Modal.Header>
                  <DiplomaticSecurity/>
                </Modal>

        


<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default DepartmentOfStateMobile;

