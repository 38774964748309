import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { FileText, InfoCircle, Search } from 'react-bootstrap-icons';

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

const MemberVotesFull = ({ bioGuideID }) => {
  const [votesData, setVotesData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;

  const fetchVotesData = () => {
    setLoading(true);
    fetch(`https://api.govglance.org/posts/recent?limit=100&skip=0&schema=united_states_of_america&table=all_member_votes&order_by=created_at&filter_column=bio_guide_id&filter_string=${bioGuideID}`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + apiKey,
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch votes data');
      }
      return response.json();
    })
    .then(data => {
      setVotesData(data);
      console.log(votesData)
      setLoading(false);
      setError(null);
    })
    .catch(error => {
      console.error('Error fetching votes data:', error);
      setError('Error fetching votes data');
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchVotesData();
  }, [bioGuideID]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error fetching data: {error}</p>;
    }
  
    if (!votesData || votesData.length === 0) {
      return <p>No data available.</p>;
    }
  
    const filteredVotesData = votesData.filter(vote => 
      vote.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    return filteredVotesData.map((vote, index) => (
      <div id='fullBillFeed' key={index}>
        <Container id='feedContainer'>
          <Card.Title id='voteTitle'>{vote.legislation_id} - {vote.title}</Card.Title>
          <Card.Body id="voteBodyText">
            <p>Date: {formatDate(vote.created_at)}</p>
            <p>Status: {vote.status}</p>
            <p>Vote: {vote.vote}</p>
            <Button id='detailsLink' onClick={(e) => {
                e.preventDefault();
                window.open(vote.url, "_blank");
              }}>
              <InfoCircle /> Source
            </Button>
            <Button id='documentLink' onClick={(e) => {
                e.preventDefault();
                window.open(vote.document_link, "_blank");
              }}>
              <FileText /> Document
            </Button>
          </Card.Body>
        </Container>
      </div>
    ));
  };

  return (
    <>
      <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search by title..."
              type="text"
              color="white"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <Button id='searchButton'>
              <Search />
            </Button>
          </Form.Group>
        </Form>
      </div>
      <div id='fullVotesFeed'>
        {renderData()}
      </div>
    </>
  );
}

export default MemberVotesFull;
